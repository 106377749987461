<template>
  <span class="breadcrumb-item">
    <span class="divider">/</span>
    <router-link v-if="to" :to="to" class="breadcrumb-title">
      <slot />
    </router-link>
    <span v-else><slot /></span>
  </span>
</template>

<script>
export default {
  name: "BreadcrumbItem",
  props: {
    to: {
      type: [String, Object]
    }
  }
};
</script>

<style scoped>
.breadcrumb-title {
  color: rgba(0, 0, 0, 0.45);
}

.divider {
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
}
</style>
