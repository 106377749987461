<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>未交付订单</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content-container">
      <a-form :form="form" class="advanced-search-form">
        <a-row class="row">
          <a-col>
            <a-row>
              <a-col :span="8">
                <a-form-item label="订单编号">
                  <a-input v-decorator="['orderSn']" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="创建时间">
                  <a-range-picker
                    v-decorator="['time']"
                    allow-clear
                    @change="onChangeTime"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="经销商">
                  <a-input v-decorator="['dealerName']" allow-clear />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-item label="订单状态">
                  <a-select
                    v-decorator="[
                      'orderStatus',
                      { initialValue: '未交付全部' }
                    ]"
                    :options="statusList"
                  ></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-button
                  type="primary"
                  icon="search"
                  class="search-button"
                  @click="onSearch"
                >
                  搜索
                </a-button>
                <a-button icon="undo" class="btn-reset" @click="onReset">
                  重置
                </a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form>

      <!-- 表格 -->
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <template slot="orderSn" slot-scope="text, record">
            <div class="ordersn-container">
              <div>{{ record.orderSn }}</div>
              <div v-if="record.porderSn" class="order-forword">
                <img
                  src="../../assets/order/icon-forword.png"
                  alt="远期订单"
                  class="icon-forword"
                />
                {{ record.porderSn }}
              </div>
            </div>
          </template>
          <template slot="dealerName" slot-scope="text, record">
            <span v-if="record.orderType === '拼单订单'">----</span>
            <div v-else>
              <div>{{ record.dealerName }}</div>
              <div class="dealer-type">
                {{ record.dealerType
                }}{{ record.storeType ? "(" + record.storeType + ")" : "" }}
              </div>
            </div>
          </template>
          <template slot="consigneeName" slot-scope="text, record">
            <span v-if="record.orderType === '拼单订单'">----</span>
            <span v-else>{{ record.consigneeName }}</span>
          </template>
          <template slot="consigneeTel" slot-scope="text, record">
            <span v-if="record.orderType === '拼单订单'">----</span>
            <span v-else>{{ record.consigneeTel }}</span>
          </template>
          <template
            v-if="!record.patchworkOrderFlag"
            slot="action"
            slot-scope="text, record"
          >
            <a-space>
              <a @click="onUndeliveredOrderDetail(record)">查看详情</a>
              <a
                v-if="record.canShip === true"
                @click="onDeliverGoods(record.orderSn)"
              >
                发货
              </a>
              <a
                v-if="record.orderStatus === '待支付'"
                @click="onFinishPay(record)"
              >
                确认收款
              </a>
            </a-space>
            <a-popconfirm
              title="确认撤销拼单?"
              ok-text="确定"
              cancel-text="取消"
              placement="topRight"
              @confirm="onCancelSpelling(record.orderSn)"
            >
              <a v-if="record.canRevocationPatchwork">
                撤销拼单
              </a>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import {
  finishPay,
  onImportProduct,
  queryUndeliveredOrderList,
  revokeSpelling
} from "../../services/OrderService";
import { tsFormat } from "../../components/DateUtils";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";

const tblColumns = [
  {
    title: "订单编号",
    dataIndex: "orderSn",
    scopedSlots: { customRender: "orderSn" },
    width: "22%"
  },
  {
    title: "创建时间",
    dataIndex: "createTime"
  },
  {
    title: "经销商",
    dataIndex: "dealerName",
    scopedSlots: { customRender: "dealerName" },
    width: "30%"
  },
  {
    title: "订单状态",
    dataIndex: "orderStatus"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      tsFormat,
      form: this.$form.createForm(this),
      tblData: [],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      statusList: [
        { value: "未交付全部", label: "全部" },
        { value: "待支付", label: "待支付" },
        { value: "待生产", label: "待生产" },
        { value: "生产中", label: "生产中" },
        { value: "待发货", label: "待发货" },
        { value: "待收货", label: "待收货" }
      ],
      startTime: "",
      endTime: ""
    };
  },
  mounted() {
    this.fetchUndeliveredOrderList();
  },
  methods: {
    // 获取未交付订单列表
    fetchUndeliveredOrderList() {
      this.loading = true;
      const params = {
        approveFlag: this.form.getFieldsValue().orderStatus,
        orderSn: this.form.getFieldsValue().orderSn,
        dealerName: this.form.getFieldsValue().dealerName,
        factoryId: localStorage.getItem("factoryId"),
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        startTime: this.startTime,
        endTime: this.endTime
      };
      queryUndeliveredOrderList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.loading = false;
          const records = resp.data.data.records;
          const data = resp.data.data;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          if (records.length > 0) {
            records.forEach(item => {
              item.createTime = tsFormat(item.createTime);
            });
          }
          this.pagination = pagination;
          if (records.length > 0) {
            records.forEach(ele => {
              if (ele.orderType === "拼单订单" && ele.procureOrderVOList) {
                if (ele.procureOrderVOList.length > 0) {
                  ele.procureOrderVOList.forEach(e => {
                    e.createTime = tsFormat(e.createTime);
                  });
                }
                ele.children = ele.procureOrderVOList;
              }
            });
          }
          this.tblData = records;
        }
      });
    },
    // 调整时间
    onChangeTime(date, dateString) {
      if (dateString && dateString.length > 0) {
        this.startTime = dateString[0];
        this.endTime = dateString[1];
      }
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchUndeliveredOrderList();
    },
    // 未交付订单详情
    onUndeliveredOrderDetail(record) {
      if (record.orderType === "拼单订单") {
        this.$router.push({
          name: "SpellDetail",
          params: {
            orderSn: record.orderSn
          }
        });
      } else {
        this.$router.push({
          name: "UndeliveredDetail",
          params: { procureOrderSn: record.orderSn }
        });
      }
    },
    // 导入生产系统
    onImportProductionSystem(record) {
      const params = {
        orderSn: record.orderSn,
        status: "待生产"
      };
      onImportProduct(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          this.fetchUndeliveredOrderList();
          this.$message.success("导入成功");
        }
      });
    },
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.fetchUndeliveredOrderList();
    },
    // 发货
    onDeliverGoods(orderSn) {
      this.$router.push({ name: "DeliverGoods", params: { orderSn: orderSn } });
    },
    // 重置
    onReset() {
      this.form.resetFields();
      this.startTime = "";
      this.endTime = "";
      this.fetchUndeliveredOrderList();
    },
    // 撤销拼单
    onCancelSpelling(orderSn) {
      revokeSpelling(orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("撤销成功");
          this.fetchUndeliveredOrderList();
        }
      });
    },
    onFinishPay(record) {
      let orderSn = "";
      if (record.fullPaymentFlag && record.orderType === "远期订单") {
        orderSn = record.porderSn;
      } else {
        orderSn = record.orderSn;
      }
      var that = this;
      this.$confirm({
        title: "请确认是否已收到线下付款?",
        okText: "确定",
        okType: "info",
        cancelText: "取消",
        onOk() {
          finishPay(orderSn).then(resp => {
            if (resp.data.code === "SUCCESS") {
              that.$message.success("支付完成");
              that.pagination.current = 1;
              that.fetchUndeliveredOrderList();
            } else {
              that.$message.error(resp.data.errorMsg);
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}
.row {
  display: flex;
}
.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}
.btn-reset {
  /*margin-bottom: 8px;*/
  transform: translateY(5px);
  margin-left: 15px;
}
.search-button {
  transform: translateY(5px);
  margin-left: 15px;
}
.ordersn-container {
  display: flex;
  flex-direction: column;
}
.dealer-type {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}
.icon-forword {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.order-forword {
  display: flex;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}
</style>
